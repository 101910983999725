.App {
  text-align: center;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.bg-slate-700 {
  /*background-image: url(images/backblue.webp);*/
  background: linear-gradient(135deg, #061532 0%, #212f73 100%);
  background-position: center center;
  background-repeat: no-repeat;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* LISTADOS */

li.listElement svg{
  position: absolute;
  right: 15px;
  top: 20px;
  font-size: 22px;
  cursor:pointer;
}

.maincontainer  {
  padding: 15px;
}

.nodisplay {
  display: none;
}

.dispblock {
  display: block;
}